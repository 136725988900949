<style scoped>
.name-list-box > em{display: none}
.name-list-box > em:first-child{display:block}

</style>
<template>

  <!-- id : contents : (S) -->
  <div id='contents'>
    <div class="board-tab-box mb-only st-message">
      <div class="tit-tab-box">
        <a href="javascript:;" :class="{on:thisCurrentTab=='receive'}" @click="onClickTab('receive')">받은 쪽지함</a>
        <a href="javascript:;" :class="{on:thisCurrentTab=='send'}" @click="onClickTab('send')">보낸 쪽지함</a>
      </div>
    </div>
    <div class="my-tit-box">
      <div class="tit-tab-box st-msg">
        <div>
          <a href="javascript:;" :class="{on:thisCurrentTab=='receive'}" @click="onClickTab('receive')">받은 쪽지함</a>
          <a href="javascript:;" :class="{on:thisCurrentTab=='send'}" @click="onClickTab('send')">보낸 쪽지함</a>
        </div>
        <div class="board-btn-box">
          <a href="javascript:;"   @click="onClickUser()">쪽지보내기</a>
        </div>          
      </div>
    </div>

    <div class="board-search-wrap st3 st-message" >
      <div class="tit-box mb-right"><a href="javascript:;" class="btn-mod-2" @click="onClickDelete">선택 삭제</a></div>
      <div class="search-box st-message">
          <select v-model="searchColumn" class="input-st1 st-selectbox wd150" style="height: 40px;line-height: 38px;">
            <option value="noteContent">내용</option>
            <option v-if="thisCurrentTab=='receive'" value="senderName">보낸사람</option>
            <option v-else-if="thisCurrentTab=='send'" value="receiverName">받는사람</option>
          </select>
          <input type="text" v-model="searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd280 dg-ml-5" style="height: 40px;line-height: 38px;" @keydown="onKeydown">
          <a href="javascript:;" @click="onClickSearch" class="btn-s dg-ml-5" style="height: 40px;line-height: 38px;">검색</a>
      </div>
    </div>

    <div class="tb-01 st2 st-over mb50">
      <table>
        <colgroup><col style="width:70px"><col style="width:70px"><col style="width:110px"><col style="width:*"><col style="width:120px"></colgroup>
        <thead>
        <tr>
          <th>
            <input type="checkbox" class="chk-st1 st-none" id="chkall" v-model="checkAll" @change="onClickCheckAll"><label for="chkall">전체선택</label>
          </th>
          <th>번호</th>
          <th v-if="thisCurrentTab=='receive'">보낸사람</th><th v-else>받는사람</th>
          <th>내용</th>
          <th>날짜</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(note, idx) in notes" :key="idx"  class="st-board">
          <td class="al-center pc-only">
            <input type="checkbox" v-model="selectCheckBox" :value="note" class="chk-st1 st-none" :id="'chk010'+idx" @change="onClickCheckBox"><label :for="'chk010'+idx">선택</label>
          </td>
          <td class="al-center pc-only">{{ pagination.totalElements - ((pagination.currentPage-1) * pagination.pageSize) - idx }}</td>
          <td class="al-center pc-only">
            <!-- {{ thisCurrentTab=='receive'?note.senderName:note.receiverName }} -->
            <span v-if="thisCurrentTab=='receive'">{{note.senderName}} 님  </span>
            <span v-if="thisCurrentTab=='send'" class="name-list-box"> 
                <em  v-for="(item, idx2) in note.noteReceiveUsers" :key="idx2">
                  <em v-if="item.receiverName" class="name">{{item.receiverName}} 님 <em v-if="note.noteReceiveUsers.length > 1">외</em></em>
                  <em v-if="item.roleName" class="name">{{item.roleName}} 전체 <em v-if="note.noteReceiveUsers.length > 1">외</em></em>
                  <em v-if="item.groupName" class="name">{{item.groupName}} 전체 <em v-if="note.noteReceiveUsers.length > 1">외</em></em>
                </em>
            </span>
            
          </td>
          <td class="pc-only ">
            <a href="javascript:;" class="ellipsis_inline wd560" @click="onClickTitle(note)">
              {{ note.noteContent }}
            </a>
          </td>
          <td class="al-center pc-only">{{ formatDate(note.createdAt) }}</td>
          <td class="mb-only">
            <div class="tb-top">
              <div class="tb-notice"><input type="checkbox" v-model="selectCheckBox" :value="note" class="chk-st1 st-none" :id="'chk010'+idx" @change="onClickCheckBox"><label :for="'chk010'+idx">선택</label></div>  
              <div class="tb-title" @click="onClickTitle(note)">{{ note.noteContent }}
              </div>
            </div>
            <div class="tb-btm"> 
              <div class="tb-date">
                {{ formatDate(note.createdAt) }}
              </div>
              <div class="tb-cmt">
                <span v-if="thisCurrentTab=='receive'">보낸사람</span><span v-else>받는사람</span>
                <em v-if="thisCurrentTab=='receive'">{{note.senderName}} 님  </em>
                <em v-if="thisCurrentTab=='send'" class="name-list-box"> 
                    <em  v-for="(item, idx2) in note.noteReceiveUsers" :key="idx2">
                      <em v-if="item.receiverName" class="name">{{item.receiverName}} 님 <em v-if="note.noteReceiveUsers.length > 1">외</em></em>
                      <em v-if="item.roleName" class="name">{{item.roleName}} 전체 <em v-if="note.noteReceiveUsers.length > 1">외</em></em>
                      <em v-if="item.groupName" class="name">{{item.groupName}} 전체 <em v-if="note.noteReceiveUsers.length > 1">외</em></em>
                    </em>
                </em> 
              </div> 
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  
    
    <!-- class : pagination (S) -->
    <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
    <!-- class : pagination (E) -->
    <CommonPopup
      v-if="memberList"
      :tap="popupTab"
      :isOpen="isOpen"
      :userId="userId"
      @click:bgDimClose="popupMemberList(false)"></CommonPopup>
 
      <div class="board-btn-box mb-only">
        <a href="javascript:;"   @click="onClickUser()">쪽지보내기</a>
      </div> 
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import DgPagination from "@/views/component/DgPagination";
import noteApi from "@/api/NoteApi";
import CommonPopup from '@/views/include/common_popoup';
import moment from "moment";

export default {
  name: "MessageList",
  props:{
    currentTab:{
      type:String
    },
    reload:{
      type:Boolean
    },
    isOpen:{
      type:Boolean,
      default:false,
    }, 
  },
  components:{
    DgPagination,
    CommonPopup,
  }, 
  data(){
    return {
      notes: [],
      pagination:{
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },
      popupTab: 'memberlistmulti',
      memberPopupOpen: false,
      memberList: false,
      userId: null,
      selectCheckBox:[],
      checkAll:false,

      searchColumn:'noteContent',
      searchKeyword:'',

      thisCurrentTab:this.currentTab
    }
  },
  watch:{
    thisCurrentTab(){
      this.onClickSearch();
    },
    isOpen(flag){
      console.log(flag);
      if(!flag) this.memberList = false
    },
    reload(e){
      if(e) {
        this.notes= [],
        this.pagination= {
          currentPage: 1,
          pageSize: 10,
          pageGroupSize: 10,
          totalPages: 0,
          totalElements: 0
        },

        this.selectCheckBox=[],
        this.checkAll=false,

        this.searchColumn='noteContent',
        this.searchKeyword='',

        this.thisCurrentTab=this.currentTab
        this.onClickSearch();
        this.$emit('click:refreshReload', false);
      }
    },
    selectCheckBox(){
      let checkSize = this.pagination.totalElements < this.pagination.pageSize? this.pagination.totalElements : this.pagination.pageSize;
      if(this.selectCheckBox.length == checkSize && checkSize != 0){
         this.checkAll = true
      }
    }
  },
  created() {
    this.onClickSearch();
  },
  methods:{
    /* 회원 검색 팝업 */
    onClickUser(){ 
      this.popupTab='message'
      this.popupMemberList(true)
      //this.$emit('click:bgDim', true)
    },  
    popupMemberList(e){
      console.log(e);
      this.memberList = e
      this.$emit('click:bgDim', this.memberList)
    },
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    onClickSearch(){
      let params = {
        size: this.pagination.pageSize,
        page: this.pagination.currentPage,
        searchColumn: this.searchColumn,
        searchKeyword: this.searchKeyword
      };
      if(this.thisCurrentTab=='receive') {
        noteApi.receiveList(params).then((data) => {
          this.notes = data.content;

          this.pagination.totalElements = data.totalElements
          let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0);
          this.pagination.totalPages = totalPages;
        })
      }else if(this.thisCurrentTab=='send'){
        noteApi.sendList(params).then((data) => {
//        console.log('???'+JSON.stringify(data))
          this.notes = data.content;

          this.pagination.totalElements = data.totalElements
          let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0);
          this.pagination.totalPages = totalPages;
        })
      }
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.checkAll=false;
      this.selectCheckBox=[];
      this.onClickSearch();
    },
    onClickTitle(note){
      this.$emit('click:changeSt', this.thisCurrentTab, 'detail', note.noteId)

    },
    onClickTab(val){
      console.log("onClickTab----234")
      this.thisCurrentTab = val
      this.status = 'list'
      this.selectCheckBox = []
      this.checkAll = false
      this.searchColumn ='noteContent',
      this.searchKeyword = ''

      this.pagination= {
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages: 0,
        totalElements: 0
      },

      this.onClickSearch();
    },
    onClickCheckAll(){
      if(this.checkAll){
        this.notes.forEach(data=>{
          this.selectCheckBox.push(data)
        })
      }else{
        this.selectCheckBox = []
      }
    },
    onClickCheckBox(){
      if(this.selectCheckBox.length == this.pagination.pageSize){
        this.checkAll=true
      }else{
        this.checkAll=false
      }
    },
    onClickDelete(){
      if(this.selectCheckBox.length==0){
        return alert('한 개 이상의 게시글을 선택해주세요.')
      }else{
          if(confirm('지금 삭제하시면 내용을 복구할 수 없습니다. \n삭제하시겠습니까?')) {
          this.selectCheckBox.forEach(data => {
            if(this.thisCurrentTab=='receive') {
              console.log("data.receiveId")
              console.log(data.receiveId)
              noteApi.deleteReceive(data.receiveId).then(() => {
                this.pagination.currentPage = 1;
                this.checkAll = false;
                this.selectCheckBox = [];
                this.onClickSearch();
              })
            }else if(this.thisCurrentTab=='send') {
              noteApi.deleteSend(data.noteId).then(() => {
                this.pagination.currentPage = 1;
                this.checkAll = false;
                this.selectCheckBox = [];
                this.onClickSearch();
              })
            }
          })
        }
      }
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickSearch();
      }
    },
    onClickBgDim(){
      this.$emit('click:bgDim', false)
    },
  }
}
</script>

<style scoped>

</style>