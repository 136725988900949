<style scoped>
.msg-send-name-box .name{margin-right:20px}
</style>
<template>

  <!-- id : contents : (S) -->
  <div id='contents'>

    <div class="my-tit-box">
      <div class="tit-tab-box">
        <a href="javascript:;" :class="{on:thisCurrentTab=='receive'}" @click="onClickTab('receive')">받은 쪽지함</a>
        <a href="javascript:;" :class="{on:thisCurrentTab=='send'}" @click="onClickTab('send')">보낸 쪽지함</a>
      </div>
    </div>

    <div class="pop-cont-box pop-memo-send-box">
      <div class="memo-hd-box st-view">
        <span v-if="currentTab=='send'">받는사람</span>
        <span v-if="currentTab=='receive'">보낸사람</span>
        <strong v-if="currentTab=='receive'">{{note.senderName}} 님</strong>
        <strong v-if="currentTab=='send'" class="msg-send-name-box ">
          <em  v-for="(note, idx) in note.noteReceiveUsers" :key="idx">
            <em v-if="note.receiverName" class="name">{{note.receiverName}} 님</em>
            <em v-if="note.roleName" class="name">{{note.roleName}} 전체</em>
            <em v-if="note.groupName" class="name">{{note.groupName}} 전체</em> 
          </em>  
        </strong>
        <!-- <strong>{{ currentTab=='send'? note.noteReceiveUsers[0].receiverName : note.senderName }} 님</strong> -->
        <div class="date-box">{{ formatDate(note.createdAt) }}</div>
      </div>
      <div class="memo-body-box" v-html="viewContent(note.noteContent)" style="min-height:340px;height:auto"></div>
      <div class="board-btn-box wd150 al-center">
        <a href="javascript:;" @click="$emit('click:changeSt',thisCurrentTab,'list',null)">목록가기</a>
        <a href="javascript:;" class="st2" @click="onClickDelete">삭제</a>
        <a href="javascript:;" @click="onClickWrite" v-if="currentTab=='receive'">답장하기</a>
      </div>
    </div>
  </div>

</template>

<script>
import noteApi from "@/api/NoteApi";
import moment from "moment";

export default {
  name: "MessageDetail",
  props:{
    currentTab:{
      type:String
    },
    noteId:{
      type:Number
    }
  },
  created(){
    this.getNote();
  },
  data(){
    return{
      status : 'detail',
      thisCurrentTab : this.currentTab,
      note : {}
    }
  },
  methods:{
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD HH:mm');
    },
    getNote(){
      if(this.currentTab=='send'){
        noteApi.getReceive(this.noteId).then(data=>{
          this.note = data;
        })
      }else{
        noteApi.get(this.noteId).then(data=>{
          this.note = data;
        })
      }
    },
    onClickDelete(){
      if(confirm('지금 삭제하시면 내용을 복구할 수 없습니다. 삭제하시겠습니까?')){
        if(this.currentTab=='send'){
          noteApi.deleteSend(this.noteId).then(data=>{
            console.log('삭제성공', data)
            alert('쪽지가 삭제되었습니다.')
            this.$emit('click:changeSt',this.thisCurrentTab, 'list', null)
          })
        }else if(this.currentTab=='receive'){
          //console.log("this.note")
          //console.log(this.note)
          //console.log("this.note.noteReceiveUsers[0].receiveId")
          //console.log(this.note.noteReceiveUsers[0].receiveId)
          noteApi.deleteReceive(this.note.noteReceiveUsers[0].receiveId).then(data=>{
            console.log('삭제성공', data)
            alert('쪽지가 삭제되었습니다.')
            this.$emit('click:changeSt',this.thisCurrentTab, 'list', null)
          })
        }
      }
    },
    onClickWrite(){
      this.$emit('click:changeSt', this.thisCurrentTab, 'write', this.noteId)
      this.$emit('click:target', this.note.createdBy)
    },
    onClickTab(val){
      this.thisCurrentTab = val
      this.status = 'list'
      this.$emit('click:changeSt',val, 'list', null)
    },
    viewContent(val){
      return val.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }
  }
}
</script>

<style scoped>

</style>