<template>

  <!-- id : contents : (S) -->
  <div id='contents'>

    <div class="my-tit-box">
      <div class="my-tit-box">쪽지 보내기</div>
    </div>

    <div class="pop-cont-box pop-memo-send-box">
      <div class="memo-hd-box">
        <span>받는사람</span>
        <strong>{{ receiver.name }} 님</strong>
      </div>
      <div class="memo-body-box">
        <textarea v-model="message" cols="30" rows="10" placeholder="메시지를 입력해주세요."></textarea>
        <div class="count-box"> {{ this.message.length }} / 1000</div>
      </div>

      <div class="board-btn-box wd150 al-center">
        <a href="javascript:;" class="st2" @click="onClickCancel">취소</a>
        <a href="javascript:;" @click="onClickSend" :style="btnStyle">보내기</a>
      </div>
    </div>
  </div>


</template>

<script>
import userApi from "@/api/UserApi";
import noteApi from "@/api/NoteApi";

export default {
  name: "message_write",
  props:{
    currentTab:{
      type:String
    },
    noteId:{
      type:Number
    },
    target:{
      type:String
    }
  },
  created(){
    userApi.get(this.target).then(data=>{
      this.receiver = data

    })
  },
  data(){
    return {
      message: '',
      receiver: null,
      btnStyle:{'cursor':'default','background': '#666666'}
    }
  },
  watch:{
    message(){
      if(this.message.length==0){
        return this.btnStyle= {
          'cursor': 'default',
          'background': '#666666'
        }
      }else{
        return this.btnStyle= {
          'cusor': 'pointer',
          'background': '#526de1'
        }
      }
    }
  },
  methods:{
    onClickCancel(){
      this.$emit('click:changeSt', this.currentTab, 'detail', this.noteId)
    },
    onClickSend(){
      if(this.message.length==0){
        return false;
      }

      let receiverUser = [{'userId':this.receiver.userId,
                           'receiverName':this.receiver.name}]
      receiverUser.push()
      let params = {
        'noteContent':this.message,
        'noteReceiveUsers':receiverUser
      }

      noteApi.save(params).then(()=>{
        alert('쪽지를 전송하였습니다.');
        this.message='';
        this.$emit('click:changeSt', this.currentTab, 'detail', this.noteId)
      })
    }
  },
}
</script>

<style scoped>

</style>